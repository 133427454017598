.home-container {
    /* padding-left: 50px;
    padding-right: 50px; */
}

.heading {
    text-align: center;
}

.heading span {
    font-size: 15px;
    color: #ec9f6b;
}

.heading h3 {
    font-size: 2rem;
    color: #202022;
    font-weight: 500;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'montserrat', sans-serif;
    text-decoration: none;
    letter-spacing: 1px;
}

html {
    scroll-behavior: smooth;
}

body {
    position: relative;
    background-color: #f5f6f7;
}


/* .home {
    background: linear-gradient(to top, rgba(0, 0, 0, .5)50%, rgba(0, 0, 0, .5)100%);
    background-position: center;
    background-size: cover;
    height: 30rem;
    
} */

.btn {
    padding: 10px 15px;
    background: #ec9f6b;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: capitalize;
}

.home .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 7rem;
}

.home .wrapper h1 {
    color: #fff;
    font-size: 2rem;
}

.home .wrapper p {
    color: #fff;
    font-size: 1rem;
    padding: 10px 0;
}

.buttons {
    margin-top: 1rem;
}

.btn2 {
    padding: 8px 15px;
    margin-left: -10px;
    color: #ec9f6b;
    border: 2px solid #fff;
    text-decoration: none;
}


/*style start for status*/


/* .status {
    padding: 0 20px;
   
    left: 0;
    right: 0;
}

.status .box-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(2rem, 1fr));
    gap: 1.5rem;
}

.status .box-container .box {
    padding: 20px;
    text-align: center;
    background: #fff;
    box-shadow: 0 2px rgba(0, 0, 0, .5);
    height: 130px;
}

.status .box-container .box h2 {
    font-size: 2rem;
    color: #202022;
}

.status .box-container .box p {
    padding: 10px 0;
    font-size: 1rem;
} */


/*  css start for about */

.about {
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3rem;
}

.about .image {
    font-size: 1 1 28rem;
    text-align: center;
    /* position: relative; */
}

.about .image img {
    font-size: 1 1 28rem;
    text-align: center;
    width: 500px;
    /* position: relative; */
}


/* .about .image::before {
    content: '';
    position: absolute;
    z-index: -1;
    background: #ec9f6b;
    height: 10rem;
    width: 10rem;
    top: -15px;
    left: -15px;
} */

.about .content {
    flex: 1 1 28rem;
    text-align: justify;
}

.about .content span {
    font-size: 15px;
    color: #ec9f6b;
    padding: 20px;
}

.about .content h3 {
    font-size: 30px;
    color: #202022;
}

.about .content p {
    padding: 1rem 0;
    font-size: 20px;
}

.about .content .award {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
    gap: 1rem 0;
    margin: 1rem 0;
}

.about .content .award .box {
    padding: .8rem;
    text-align: center;
    background: #eee;
    border: 1px solid #000;
}


/* .mission {
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    padding: 50px;
} */


/* about css start  */

.mission {
    margin-right: 80px;
    margin-left: 80px;
}

.mission h2 {
    font-size: 20px;
    color: #EDA90F;
    text-align: center;
    padding-top: 10px;
}

.mission_vision {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    gap: 1.5rem;
    justify-content: center;
    margin-top: 2rem;
}

.mision {
    border: 1px solid #111;
    /* width: 48%; */
}

.mision p {
    padding-left: 40px;
    padding-right: 40px;
    text-align: justify;
}

.vision p {
    padding-left: 40px;
    padding-right: 40px;
    text-align: justify;
}

.mision img {
    width: 50px;
}

.vision img {
    width: 50px;
}

.vision {
    border: 1px solid #111;
    /* width: 48%; */
}


/*  css end for about */


/*css start for service*/

.service-section {
    justify-content: center;
    text-align: center;
    background-color: #0f172a;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 40px;
}

.service-section2 {
    justify-content: center;
    text-align: center;
    background-color: #d9dee8;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 40px;
}

.service-section h3 {
    font-size: 30px;
    color: #fff;
    padding-top: 20px;
}

.service {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    gap: 1.5rem;
    justify-content: center;
    margin-top: 2rem;
}

.service2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap: 1.5rem;
    justify-content: center;
    margin-top: 2rem;
}

.service-content {
    display: flex;
    gap: 20px;
    padding: 30px;
    color: #fff;
    border-radius: 10px;
    background-color: #394255;
}

.service-content2 {
    display: inline;
    gap: 20px;
    padding: 30px;
    color: #fff;
    border-radius: 10px;
    background-color: #394255;
}

.service-content h2 {
    color: #ec9f6b;
    font-size: 25px;
    text-align: left;
}

.service-content2 h2 {
    color: #ec9f6b;
    font-size: 25px;
    text-align: left;
}

.service-content p {
    text-align: left;
}

.service-content2 p {
    text-align: left;
}

.service-content2 img {
    height: 25rem;
    width: 75%;
}

.reviews {
    padding: 20px 50px;
    margin-top: 1.5rem;
}

.reviews .clients-reviews {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
}

.reviews .clients-reviews .review {
    padding: 1.5rem;
    background: #fff;
    border: 1px solid #aaa;
}

.review .info {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: .5rem;
}

.review .info img {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
}

.review .info .row h1 {
    font-size: 1.3rem;
    font-weight: 500;
}

.review .info .row span {
    font-size: 12px;
    color: #ec9f6b;
}


/* .contact {
    background: linear-gradient(to top, rgba(0, 0, 0, .5)50%, rgba(0, 0, 0, .5)100%);
    background-position: center;
    background-size: cover;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
} */

.contact {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap: 1.5rem;
    justify-content: center;
    margin-top: 2rem;
}

.contact-content {
    /* display: flex; */
    text-align: center;
    justify-content: center;
    background: white;
}

.form {
    display: flex;
    text-align: center;
    justify-content: center;
    background: white;
    gap: 50px;
}

.form textarea {
    height: 150px;
}

.form-flex {
    display: inline;
}


/* .footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    gap: 2rem;
    margin-top: 2rem;
} */

.copy {
    background: #ec9f6b;
    padding: 1rem;
    text-align: center;
    color: #fff;
}


/* admin css  start*/

.admin-header {
    background: #0f172a;
    width: 100;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: white;
    list-style: none;
}

.admin-content {
    display: flex;
}

.admin-sideBar1 {
    background: #0f172a;
}

.admin-sideBar1 ul {}

.admin-sideBar1 li {
    list-style: none;
    text-decoration: none;
    padding: 20px;
}

.admin-sideBar1 li a {
    text-decoration: none;
    line-height: 20px;
    font-size: 15px;
    color: white;
}

.admin-sideBar2-header {
    text-align: center;
}


/*  css end for admin */


/* css start for footer */

.footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    background-color: #0f172a;
    gap: 300px;
    padding: 80px;
    border-bottom: 1px solid #fff;
}

.footer-content {
    /* width: 500px; */
}

.footer-content span {
    padding-left: 20px;
}

.footer-link {
    display: flex;
    padding-top: 5px;
    gap: 20px;
    width: 200px;
}

.footer-content h2 {
    color: #fff;
    font-size: 30px;
    text-align: left;
}

.footer-content p {
    color: #fff;
    font-size: 15px;
    text-align: left;
    margin-top: 30px;
}

.footer-content h6 {
    color: #fff;
    font-size: 15px;
    text-align: left;
    margin-top: 15px;
}

.location {
    padding-left: 40px;
    font-size: 15px;
}

.poweredByDiv {
    background-color: #0f172a;
    justify-content: center;
}

.poweredByDiv h4 {
    color: #fff;
    font-size: 15px;
    padding: 20px;
    text-align: center;
}

.poweredByDiv a {
    color: #ec9f6b;
    font-size: 15px;
    padding: 20px;
    text-align: center;
    text-decoration: none;
}

.poweredByDiv span {
    float: right;
}


/*  css end for footer */


/* ScrollToTopButton.css */

.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    /* z-index: 1000; */
}

.scroll-to-top-button:hover {
    background-color: #0056b3;
}


/* Background css start */

.background {
    height: 38rem;
    width: 100%;
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: none;
    object-fit: cover;
    z-index: -1;
}


/* homesite css start */

.homeSite {
    display: flex;
    background-color: #d4d7dd;
    /* grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    gap: 1.5rem;
    background-color: #020e2c; */
    /* margin-top: 2rem; */
}

.homeSite-coll {
    padding: 100px;
}

.homeSite-coll img {
    width: 400px;
}

.homeSite-coll h1 {
    font-size: 50px;
    color: #1e803f;
}

.homeSite-coll p {
    font-size: 20px;
}


/* homesite css end */

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 500ms ease-in-out;
}


/* herr css start */

.hero {
    margin: 0px 120px;
    /* padding-top: -1000px; */
    /* position: absolute; */
}

.hero-text {
    color: #fff;
    font-size: 80px;
    font-weight: 500;
    line-height: 100px;
    text-align: center;
}

.hero-explore img {
    width: 25px;
    height: 25px;
}

.hero-explore {
    display: flex;
    height: 40px;
    align-items: center;
    gap: 50px;
    width: fit-content;
    margin-top: 120px;
    padding: 5px 8px;
    padding-left: 30px;
    border-radius: 60px;
    background-color: #fff;
    cursor: pointer;
}

.hero-explore p {
    color: #0056b3;
    padding-top: 8px;
}

.hero-dot-play {
    margin-top: 100px;
    display: flex;
    justify-content: center;
}

.hero-dots {
    display: flex;
    align-items: center;
    list-style: none;
}

.hero-dot {
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 7.5px;
    cursor: pointer;
    text-align: center;
}

.hero-dot.orange {
    background: orangered;
}

.hero-play {
    /* margin-left: 400px; */
    display: flex;
    margin-top: -100px;
    align-items: center;
    gap: 30px;
}

.hero-play p {
    color: #0056b3;
    font-size: 19px;
}


/*news css start*/

.newslist {
    padding: 50px;
    width: 50%;
    /* justify-content: center; */
}

.timezone {
    display: flex;
}

.timezone span {
    font-size: 20px;
    padding-left: 20px;
    color: #ec9f6b;
}

.news {
    gap: 10px;
}

.news img {
    width: 100%;
    height: 400px;
}


/*news css end*/


/*admin dashboard*/

@media (max-width:820px) {
    .home {
        /* height: 25rem; */
        /* display: inline; */
    }
    .homeSite {
        display: inline;
        gap: 2px;
        justify-content: center;
    }
    .homeSite-coll {
        padding: 10px;
        align-items: center;
    }
    .service-section {
        justify-content: center;
        text-align: center;
        background-color: #0f172a;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
    }
    .home header {
        padding: 10px;
    }
    header .btn {
        display: none;
    }
    header .menubtn {
        display: block;
    }
    /* header .navbar{
      position: absolute;
      top: 10%;
      left: 0; right: 0;
      background: #fff;
      padding: 10px;
      clip-path:polygon(0 0, 100% 0, 100% 0,0 0);
      transition: .2s linear;
    }
    header .navbar.active{
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    header .navbar a{
        display:block;
        color: #202022;
        font-size: 10px;
        margin: 10px 0;
    } */
    .home .wrapper {
        text-align: center;
        margin-top: 8rem;
    }
    .home .wrapper h1 {
        font-size: 1.2rem;
    }
    .home .wrapper p {
        font-size: .8rem;
        width: 90%;
    }
    .home .status {
        display: none;
    }
    .about,
    /* .service, */
    .reviews,
    .contactinfo,
    footer {
        padding: 15px;
    }
    .about {
        margin-top: 2rem;
    }
    .about .image img {
        width: 100%;
        object-fit: cover;
    }
    .about .image::before {
        display: none;
    }
    .contact form {
        width: 90%;
    }
    .footer a {
        font-size: 12px;
    }
    .contact-content {
        width: 100%;
        padding: 5px;
    }
    .footer {
        padding: 5px;
        gap: 20px;
    }
    .footer-content {
        padding: 20px;
    }
    .mission {
        margin-right: 10px;
        margin-left: 10px;
    }
    .mission_vision {
        margin-top: 2px;
    }
}

@media screen and (max-width: 600px) {
    .navbar a:not(:first-child) {
        display: none;
    }
    .navbar a.menubtn {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .navbar.responsive {
        position: relative;
    }
    .navbar.responsive .menubtn {
        position: absolute;
        right: 0;
        top: 0;
    }
    .navbar.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}